// prefer default export if available
var preferDefault = function preferDefault(m) {
  return m && m.default || m;
};

exports.components = {
  "component---src-templates-home-js": function componentSrcTemplatesHomeJs() {
    return import("/opt/build/repo/src/templates/home.js"
    /* webpackChunkName: "component---src-templates-home-js" */
    );
  },
  "component---src-templates-post-js": function componentSrcTemplatesPostJs() {
    return import("/opt/build/repo/src/templates/post.js"
    /* webpackChunkName: "component---src-templates-post-js" */
    );
  }
};